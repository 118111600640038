.upperText{
    font-size: 0.9vw;
    font-weight: 640;
    width: 80%;
    margin: 10px auto;
   text-align: justify;
 
  

}
.tablebackgroundouter {
  background-color: white;
  /* border: 1px solid red; */
  /* height: 300px; */
  padding: 10px 30px;
  /* overflow: auto; */

 
 
}
.middleText{
   margin: 10px 0px 10px 0px; 
}
.textRed{
    margin-bottom: 6px;
    color: rgba(250,84,66,.8392156862745098);;
}
.reimbursementPage{
  display: flex;
  width: 100%;
}


/* left component */
.progInfoTitle {
  font-family: Lato;
  font-size: 26px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #162242;
  text-transform: uppercase;
}
.progInfoTitleR {
  font-family: Lato;
  font-size: 3.5vw;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #162242;
  text-transform: uppercase;
}
.progInfoContainerR {
  padding: 0 !important;
  margin-top: 101px !important;
  position: relative;
 
}
.title{
    
    font-family: Lato;
    font-size: 30px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #162242;
    text-transform: uppercase;
    padding: 10px 10px;
}
.drpDown{
    width: 100px;
}

.datePicker{
    margin-left: 50px;
    width: 100%;
    background-color: #f8f8f8;
}
.MuiInputBase-root.MuiInputBase-adornedEnd{
height: 41px;
}
.captionText{
font-size: 13px;
font-weight: 650;
margin: 40px 0px 20px 0px;
}
.S_Button{
border-radius: 50px;
}
.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1{
font-size: 13px !important;
}
.labelledText label{
    margin-bottom: -0.5rem;

}
  /* Modal1 */
  .MuiFormControl-root{
    width: 100%;
    background-color: rgb(249 249 249 / 9%);
  }
  .MuiFilledInput-multiline{
 height: 100px;
 background-color: rgb(249 249 249 / 9%);
  }
  .MuiFormLabel-root.Mui-disabled{
    padding-left: 10px;
  }
  .MuiFormLabel-root.Mui-focused{
color: #533278 !important;
  }
  .MuiFilledInput-underline.Mui-focused:after{
    border-bottom: 2px solid #533278 !important;
  }
  
  
  .mqalert_button_modal{
    display: flex;
    justify-content: end;
    align-items: center;
    flex-wrap: nowrap;
    padding: 0.5rem;
    border-top: 1px solid lightgrey;
  }
  .mqalertbutton{
   border-radius: 30px;
  }
  .mqalertbutton_cancel{
    border-radius: 30px;
    background-color: transparent !important;
    color: #000000;
  }

  .MuiButtonBase-root.MuiButton-root.r-btn{
    font-size: 14px;
    font-weight: bold;
    margin: 20px 0px 10px 0px;
    height: 40px;
    width: 100%;
    color: #4782c4;
    background-color: #fff;
    border-color: #4782c4;
    background-image: none;
    border: 1px solid #4782c4;
    border-radius: 20px;
    padding-bottom: 8px;
  }
  .r-btn {
    font-size: 14px;
    font-weight: bold;
    margin: 20px 0px 10px 0px;
    height: 40px;
    width: 100%;
    color: #4782c4;
    background-color: #fff;
    border-color: #4782c4;
    background-image: none;
    border: 1px solid #4782c4;
    border-radius: 20px;
    padding-bottom: 32px;
  }
  .r-btn:hover {
    color: #fff;
    background-color: #febf42;
  }


  .MuiTableHead-root{
    overflow-y: auto !important;
    top: 0 !important;
    z-index:999 !important;
   
  }
  
  .MuiTableCell-stickyHeader{
    position:static !important;
    top: 0 !important;
  }
  .MuiTableContainer-root{
    overflow-y: auto !important;
   
  }
  .datePicker .MuiFormLabel-root{
    padding-left: 14px!important;
  }
  .datePicker .MuiInputBase-input {
padding-left: 10px !important;
  }
.MuiSelect-filled.MuiSelect-filled{
    background-color: transparent !important;
  }
  
  @media only screen and (max-width: 767px){
    .upperText{
font-size: 20px;
    }
  }
  @media only screen and (max-width: 900px){
    .upperText{
      font-size: 9px;
      font-weight: 640;
      width:80%;
      margin: 10px auto;
  }
  }



