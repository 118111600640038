.id-card-front .hidden.printable {
  display: none;
}

.member-list-container {
  max-height: 230px;
  overflow-y: auto;
}

.demoprint {
  font-size: 18px;
  color: red;
}

/* ===================old csss====================== */

@media print {
  .id-card-front .hidden.printable {
    display: block !important;
    -webkit-print-color-adjust: exact;
  }
  .container {
    padding-left: 0 !important;
  }
  .Rectangle {
    height: auto !important;
    border: 1px solid gray;
    margin-bottom: 40px !important;
  }

  #root {
    display: none;
  }
  .close-flip-div {
    visibility: hidden !important;
  }

  .footer-section {
    background-color: #162242 !important;
    -webkit-print-color-adjust: exact;
  }
  .contact-section {
    background-color: #162242 !important;
    -webkit-print-color-adjust: exact;
  }

  .id-card-back-main {
    display: none !important;
  }
}

.cardZindex {
  z-index: 999;
}
.tap_image_rotate {
  z-index: 1;
  top: 230px;
  left: 44%;
  opacity: 0.5;
  width: 80px;
}
.tap_image_back-rotate {
  z-index: 1;
  top: 194px;
  left: 44%;
  opacity: 0.5;

  width: 67px;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .landscape_view_mobile_rotate {
    -webkit-transform: rotate(360deg) scale(1.5, 1.3); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg) scale(1.5, 1.3); /* IE 9 */
    transform: rotate(360deg) scale(1.5, 1.3); /* Firefox 16+, IE 10+, Opera */

    position: relative;
  }

  .landscape-mode-for_digital_card {
    -webkit-transform: rotate(270) scale(1.7, 1.3); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(270deg) scale(1.7, 1.3); /* IE 9 */
    transform: rotate(270deg) scale(1.7, 1.3); /* Firefox 16+, IE 10+, Opera */

    position: relative;
  }

  .landscape-mode-for_digital_card_new_add {
    -webkit-transform: rotate(360deg) scale(0.9, 0.8); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg) scale(0.9, 0.8); /* IE 9 */
    transform: rotate(360deg) scale(0.9, 0.8); /* Firefox 16+, IE 10+, Opera */

    position: relative;
  }
  /* .tap_image_back-rotate{
        z-index: 1;
        top: 194px;
        left: 44%;
        opacity: 0.5;

        width: 67px;
    }
      .tap_image_rotate{
        z-index:1;
        top: 169px;
        left: 44%;
        opacity: 0.5;
        width: 80px;
      } */
  .fview {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red;  */
    border-radius: 0.8vw;
    margin: 0vw 2vw;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    background-color: white;
    margin-top: -54px;
    /* height:56vw;  */
  }

  .tap_image_landscapeBack {
    z-index: 1;
    top: 200px;
    left: 44%;
    opacity: 0.5;
  }

  .tap_image {
    z-index: 1;
    top: 85px;
    left: 44%;
    width: 60px;
    opacity: 0.5;
  }
  .tap_image_back {
    z-index: 1;
    top: 80px;
    left: 44%;
    width: 60px;
    opacity: 0.5;
  }
  .fv_background_image_blue {
    /* border: 1px solid red;
        background-image: url('../../Images//Drawer/group-51.png');
        background-repeat: no-repeat;
        background-size: contain;
        width: 100%; */
    background-image: url('../../Images//Drawer/card_bg_02.png');
    background-repeat: no-repeat;
    background-size: 55% 100%;
  }
  .fv_left_logo {
    /* width: 8vw;
        height: 6vh; */
    width: 35vw;
    height: auto;
    object-fit: contain;
    /* border:1px solid red; */
  }
  .fv_right_logo {
    /* width: 8vw;
        height: 6vh; */
    width: 25vw;
    object-fit: contain;
    height: auto;
  }
  .fv_card {
    /* border: 1px solid red; */
    width: 100%;
    /* height: 300px; */
    display: flex;
    justify-content: space-between;
    height: auto;
    /* padding: 1vh 2vw; */
  }
  .fv_left_container {
    /* border: 1px solid black; */
    /* width: 65%; */
    width: 55%;

    /* background-image: url('../../Images//Drawer/group-51.png');
        background-repeat: no-repeat;
        background-size: contain; */
    /* padding: 1vh 2vw; */
    padding: 1vh 3vw;
  }
  .fv_adult_top_name_blue {
    /* width: 118.1px; */
    /* height: 10.3px; */
    /* font-family: Roboto; */
    /* font-size: 2.5vw; */
    font-size: 1.8vw;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #28324b;
  }
  .fv_username {
    margin-bottom: 1.5vw;
    /* width: 55.6px; */
    /* height: 10.3px; */
    /* font-family: Roboto; */
    /* font-size: 2.5vw; */
    font-size: 1.8vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #8850ab;
  }
  .fv_name_container {
    margin-top: 1.5vw;
  }
  .fv_list_adultname_blue {
    /* width: 92.8px; */
    /* height: 44.4px; */
    /* font-family: Roboto; */
    /* font-size: 2.3vw; */
    font-size: 1.6vw;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #28324b;
    /* width: 15vw; */
    width: 100%;
  }
  .fv_list_username {
    /* width: 43.7px; */
    /* height: 53.4px; */
    /* font-family: Roboto; */
    font-size: 1.8vw;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #8850ab;
    margin-left: 0.5vw;

    /* border: 1px solid red; */
    flex: 1;
  }

  .fv_right_container {
    /* border: 1px solid blue; */
    /* flex: 1; */
    width: 45%;
    padding: 1vh 2vw;
  }

  .fv_joined_text {
    /* width: 63.1px; */
    /* height: 10.3px; */
    /* font-family: Roboto; */
    font-size: 1.8vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }
  .fv_table_left_text {
    /* width: 7rem; */
    /* border:1px solid red; */
    /* width: 46.9px; */
    /* height: 80.6px; */
    /* font-family: Roboto; */
    font-size: 1.8vw;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #541957;
    margin-bottom: 4px;
  }
  .fv_table_right_text {
    /* width: 5rem;
            border:1px solid red; */
    /* width: 43.7px; */
    /* height: 80.6px; */
    /* font-family: Roboto; */
    font-size: 1.8vw;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4f1252;
    margin-bottom: 4px;
  }
  .fv_table_right_text_groupNo {
    /* width: 5rem;
            border:1px solid red; */
    /* width: 43.7px; */
    /* height: 1.8vw; */
    /* font-family: Roboto; */
    font-size: 1.8vw;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4f1252;
    margin-bottom: 4px;
  }
  .fv_table_middle_text {
    /* border: 1px solid green; */
    /* width: 43.7px; */
    /* height: 80.6px; */
    /* font-family: Roboto; */
    font-size: 1.6vw;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4f1252;
    margin: 0.5vw 0px;
  }
  .fv_center_details_text {
    padding: 1.5vw 3vw;
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    /* width: 291.1px; */
    /* height: 21.6px; */
    /* font-family: Roboto; */
    font-size: 1.6vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }

  /* ====================Backside===================== */
  .bv {
    /* width: 80%; */
    /* border: 1px solid red; */
    /* height: 200px; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* align-items: center; */
    /* padding:1vw 2vw; */
    border-radius: 0.8vw;
    /* height: 25.2vw; */
    margin: 0vw 6vw;
    /* border:1px solid red; */
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    background-color: white;
    /* height: 60vw; */
    padding: 2vw 2vw;
    margin-top: -45px;
  }

  .bv_top_image_container {
    display: flex;
    /* border: 1px solid blue; */
    justify-content: center;
    align-items: center;
    padding: 0.8vw 2vw;
  }
  .bv_thank_image {
    width: 20vw;
    height: auto;
    object-fit: contain;
  }
  .bv_thank_image_smartshare {
    width: 35vw;
    height: auto;
    object-fit: contain;
  }
  .bv_sharing_image {
    width: 9vw;
    height: auto;
    object-fit: contain;
    margin-left: -0.9vw;
  }
  .bv_sharing_image_smartshare {
    width: 23vw;
    height: auto;
    object-fit: contain;
    margin-left: -0.9vw;
  }
  .bv_details_text_div {
    padding: 0.3vw 2vw;
  }

  .bv_bluetext_common {
    /* border:1px solid red; */
    width: 100%;
    /* height: 2.0vw; */
    /* font-family: Roboto; */
    font-size: 1.6vw;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #162242;
  }
  .bv_bluetext_new_common {
    font-size: 1.6vw;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #162242;
    font-weight: bold;
  }
  .bv_second_container {
    margin-top: 1.8vw;
  }
  .bv_blacktext_common {
    /* width: 291.1px; */
    /* height: 84.3px; */
    /* font-family: Roboto; */
    font-size: 1.6vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }
  .margintop_small {
    margin-top: 0.9vw;
  }
  .bv_card_no_bottom {
    /* border: 1px solid blue; */
    /* padding: 0.5vw 2vw; */
    display: flex;
    justify-content: flex-end;
    align-items: center;

    /* width: 56.9px; */
    /* height: 5.9px; */
    /* font-family: Roboto; */
    font-size: 1.4vw;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }
  .bv_black_bold_text {
    font-weight: bold;
  }

  .mob_scrollbarview {
    /* border: 1px solid red; */
    height: 20vw;
    overflow: auto;
    overflow-y: auto;
  }
  ::-webkit-scrollbar {
    /* width: 0px;
                background: transparent;  */
  }

  .bv_new_center_container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .bv_black_italic_text {
    /* margin-left: 5px; */
    font-size: 1.6vw;
    color: #000000;
    font-weight: bold;
    font-style: italic;
    /* border:1px solid red; */
    margin-top: 10px;
    margin-bottom: 5px;
    text-align: center;
    width: 12rem;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .landscape-mode-for_digital_card {
    -webkit-transform: rotate(270deg) scale(1.1); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(270deg) scale(1.1); /* IE 9 */
    transform: rotate(270deg) scale(1.1); /* Firefox 16+, IE 10+, Opera */

    position: relative;
  }

  .landscape-mode-for_digital_card_new_add {
    -webkit-transform: rotate(360deg) scale(0.9, 0.8); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg) scale(0.9, 0.8); /* IE 9 */
    transform: rotate(360deg) scale(0.9, 0.8); /* Firefox 16+, IE 10+, Opera */

    position: relative;
  }
  .container {
    width: 90% !important;
  }

  .fview {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
    border-radius: 0.8vw;
    /*margin: 0vw 4vw;*/
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    background-color: white;
    /* height: 51vw; */
  }
  .fv_background_image {
    /* background-image: url('../../Images//Drawer/group-51.png');
        background-repeat: no-repeat;
        background-size: contain;
        width: 100%; */
    background-image: url('../../Images//Drawer/group-51.png');
    background-repeat: no-repeat;
    background-size: 55% 100%;
  }
  .fv_background_image_blue {
    /* border: 1px solid red;
        background-image: url('../../Images//Drawer/group-51.png');
        background-repeat: no-repeat;
        background-size: contain;
        width: 100%; */
    background-image: url('../../Images//Drawer/card_bg_02.png');
    background-repeat: no-repeat;
    background-size: 55% 100%;
  }
  .fv_left_logo {
    /* width: 8vw;
        height: 6vh; */
    width: 35vw;
    height: auto;
    object-fit: contain;
    /* border:1px solid red; */
  }
  .fv_right_logo {
    /* width: 8vw;
        height: 6vh; */
    width: 25vw;
    object-fit: contain;
    height: auto;
  }
  .fv_card {
    /* border: 1px solid red; */
    width: 100%;
    /* height: 300px; */
    display: flex;
    justify-content: space-between;
    height: auto;
    /* padding: 1vh 2vw; */
  }
  .fv_left_container {
    /* border: 1px solid black; */
    width: 55%;

    /* background-image: url('../../Images//Drawer/group-51.png');
        background-repeat: no-repeat;
        background-size: contain; */
    /* padding: 1vh 2vw; */
    padding: 1vh 3vw;
  }
  .fv_adult_top_name {
    /* width: 118.1px; */
    /* height: 10.3px; */
    /* font-family: Roboto; */
    font-size: 1.8vw;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }
  .fv_username {
    margin-bottom: 1.5vw;
    /* border:1px solid red; */
    /* width: 55.6px; */
    /* height: 10.3px; */
    /* font-family: Roboto; */
    font-size: 1.8vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #8850ab;
  }
  .fv_name_container {
    margin-top: 1.5vw;
  }
  .fv_list_adultname {
    /* width: 92.8px; */
    /* height: 44.4px; */
    /* font-family: Roboto; */
    font-size: 1.6vw;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #8c1342;
    width: 15vw;
  }
  .fv_list_username {
    /* width: 43.7px; */
    /* height: 53.4px; */
    /* font-family: Roboto; */
    font-size: 1.6vw;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #8850ab;
    margin-left: 0.5vw;
    /* border: 1px solid red; */
    flex: 1;
  }
  .fv_right_container {
    /* border: 1px solid blue; */
    /* flex: 1; */
    width: 45%;
    padding: 1vh 2vw;
  }

  .fv_joined_text {
    /* width: 63.1px; */
    /* height: 10.3px; */
    /* font-family: Roboto; */
    font-size: 1.8vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }
  .fv_table_left_text {
    /* width: 46.9px; */
    /* height: 80.6px; */
    /* font-family: Roboto; */
    font-size: 1.6vw;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #541957;
  }
  .fv_table_right_text {
    /* width: 43.7px; */
    /* height: 80.6px; */
    /* font-family: Roboto; */
    font-size: 1.6vw;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4f1252;
  }
  .fv_table_right_text_groupNo {
    /* width: 5rem;
            border:1px solid red; */
    /* width: 43.7px; */
    /* height: 1.8vw; */
    /* font-family: Roboto; */
    font-size: 1.8vw;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4f1252;
    margin-bottom: 4px;
  }
  .fv_table_middle_text {
    /* border: 1px solid green; */
    /* width: 43.7px; */
    /* height: 80.6px; */
    /* font-family: Roboto; */
    font-size: 1.6vw;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #98335b;
    margin: 0.5vw 0px;
  }
  .fv_center_details_text {
    padding: 1.5vw 3vw;
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    /* width: 291.1px; */
    /* height: 21.6px; */
    /* font-family: Roboto; */
    font-size: 1.6vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }

  /* ====================Backside===================== */
  .bv {
    /* border: 1px solid red; */
    /* height: 200px; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* align-items: center; */
    /* padding:1vw 2vw; */
    border-radius: 0.8vw;
    /* height: 25.2vw; */
    margin: 0vw 6vw;
    /* border:1px solid red; */
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    background-color: white;
    margin-top: -25px;
  }

  .bv_top_image_container {
    display: flex;
    /* border: 1px solid blue; */
    justify-content: center;
    align-items: center;
    padding: 0.8vw 2vw;
  }
  .bv_thank_image {
    width: 20vw;
    height: auto;
    object-fit: contain;
  }
  .bv_sharing_image {
    width: 13vw;
    height: auto;
    object-fit: contain;
    margin-left: -0.9vw;
  }
  .bv_details_text_div {
    padding: 0.3vw 2vw;
  }
  .bv_bluetext_common {
    /* border:1px solid red; */
    width: 100%;
    /* height: 2.0vw; */
    /* font-family: Roboto; */
    font-size: 1.6vw;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #162242;
  }
  .bv_bluetext_new_common {
    font-size: 1.6vw;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #162242;
    font-weight: bold;
  }
  .bv_second_container {
    margin-top: 1vw;
  }
  .bv_blacktext_common {
    /* width: 291.1px; */
    /* height: 84.3px; */
    /* font-family: Roboto; */
    font-size: 1.6vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }
  .margintop_small {
    margin-top: 0.3vw;
  }
  .bv_card_no_bottom {
    /* border: 1px solid blue; */
    padding: 0.3vw 2vw;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    /* width: 56.9px; */
    /* height: 5.9px; */
    /* font-family: Roboto; */
    font-size: 0.8vw;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }
  .bv_black_bold_text {
    font-weight: bold;
  }
  .mob_scrollbarview {
    /* border: 1px solid red; */
    height: 20vw;
    overflow: auto;
    overflow-y: auto;
  }
  ::-webkit-scrollbar {
    /* width: 0px;
                background: transparent;  */
  }

  .bv_new_center_container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .bv_black_italic_text {
    /* margin-left: 5px; */
    font-size: 1.6vw;
    color: #000000;
    font-weight: bold;
    font-style: italic;
    /* border:1px solid red; */
    margin-top: 10px;
    margin-bottom: 5px;
    text-align: center;
    width: 25rem;
  }
}

.fv_bottom {
  /* border: 1px solid green; */
  width: 100%;
}
.fv_top_image_container {
  /* border:1px solid green; */
  display: flex;
  height: 20vw;
  justify-content: space-between;
  /* padding: 1vh 2vw; */
  padding: 1vh 0vh;
  padding-right: 2vw;
}

.fv_list_view {
  display: flex;
  /* border:1px solid red; */
  justify-content: space-between;
  align-items: center;
}

/* =====================right container======= */

.fv_right_table1 {
  display: flex;
  /* border: 1px solid red; */
  justify-content: space-between;
}
.fv_left_table {
  /* border: 1px solid black; */
  width: 55%;
}
.fv_right_table {
  /* border: 1px solid blue; */
  width: 45%;
}

.fv_right_new_table {
  /* border: 1px solid greenyellow; */
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
}
.fv_new_table_left_text {
  /* border: 1px solid greenyellow; */
  width: 55%;

  /* width: 46.9px; */
  /* height: 80.6px; */
  /* font-family: Roboto; */
  font-size: 1.6vw;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #612b64;
}
.fv_new_table_right_text {
  /* border: 1px solid greenyellow; */
  width: 45%;

  /* width: 43.7px; */
  /* height: 80.6px; */
  /* font-family: Roboto; */
  font-size: 1.6vw;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #420045;
  word-wrap: break-word;
}
.fv_space_2table {
  margin-top: 2.5vw;
}

.bottomview_image_and_no {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 2vw;
  /*  border:2px solid red; */
}
.bottom_link_backview {
  font-size: 1.3vw;
  font-weight: 500;
}

.azmc_top_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.azmc_id_card_no {
  background-color: #4a0b4d;
  color: white;
  font-weight: 300;
  padding: 0px 5px;
  font-size: 1.6vw;
}
.bv_mob_bold {
  font-weight: bold;
}
.azmc_id_card_no_bottonm {
  margin-top: 5px;
  background-color: #4a0b4d;
  color: white;
  font-weight: 300;
  padding: 0px 5px;
  font-size: 1.6vw;
  float: right;
}
.botom_container_smaratshare {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0vw 2vw;
}

.idcard_front_back_name {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* width: 115px; */
  /* height: 24px; */
  /* font-family: Roboto; */
  font-size: 4.3vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.15px;
  color: #000000;
  padding: 4vw 4vw;
}
/* ===================Backview of card================= */

.bv_card_emd_no_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bv_edi_no {
  background-color: #4a0b4d;
  color: white;
  font-weight: 500;
  padding: 0px 5px;
  font-size: 1.6vw;
}

@media screen and (min-width: 600px) and (max-width: 1600px) {
  .fview {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red;  */
    border-radius: 0.8vw;
    margin: 0vw 2vw;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    background-color: white;
    margin-top: -54px;
    /* height:56vw;  */
  }
  .tap_image,
  .tap_image_back {
    z-index: 1;
    top: 200px;
    left: 45%;
    width: 100px;
    opacity: 0.5;
  }
  .cardWrapper .container {
    width: 100% !important;
  }
  .zoomBtnWrap .MuiButtonBase-root {
    margin: 0 !important;
    float: right;
    top: 80px;
    right: 40px;
    width: 80px;
    height: 80px;
  }
  .zoomBtnWrap .MuiSvgIcon-root {
    font-size: 2.5rem;
  }
  .close_card {
    top: 30px;
    width: 40px;
    height: 40px;
  }
  .close_card_back {
    top: 55px;
    right: 50px;
    width: 40px;
    height: 40px;
  }
  .close_card .MuiSvgIcon-root,
  .close_card_back .MuiSvgIcon-root {
    font-size: 2.5rem !important;
  }
}
/*-- For IPad --*/
@media screen and (min-width: 765px) and (max-width: 899px) {
  .tap_image,
  .tap_image_back {
    z-index: 1;
    top: 150px;
    left: 45%;
    width: 80px;
    opacity: 0.5;
  }
  .zoomBtnWrap .MuiButtonBase-root {
    margin: 0 !important;
    float: right;
    top: 60px;
    right: 40px;
    width: 60px;
    height: 60px;
  }
  .zoomBtnWrap .MuiSvgIcon-root {
    font-size: 2rem;
  }
  .close_card {
    top: 4px;
  }
  .close_card_back {
    top: 40px;
    right: 30px;
  }
}

@media (max-width: 750px) and (orientation: landscape) {
  .tap_image_rotate,
  .tap_image_back-rotate {
    top: 185px !important;
    width: 80px !important;
  }
}
