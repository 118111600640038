.chatbody {
  flex-grow: 1;
  padding: 20;
  overflow-y: scroll;
  margin-top: 20px;
}
::-webkit-scrollbar-thumb {
  background: #cccccc !important;
  border-radius: 10px;
}
.close {
  color: #fff !important;
}
.betaLogo {
  width: 12% !important;
  margin-bottom: -20px;
}
.barTitleWidget {
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: left;
  color: #454d58;
  margin-bottom: 3px;
}
.amtWrapperWidget {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 4px;
}
.metAmtWidget {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #eb5757;
}
.header-container {
  width: 100%;
  height: 60px;
  background-color: #533278;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-container .logo {
  width: 158px;
}
.header-container .caption {
  width: 39%;
  margin: 0 auto;
  font-family: Tungsten;
  font-size: 22px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 1.44px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
}
.header-container .icons-header {
  width: 13%;
  text-align: right;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 1.44px;
  color: #fff;
  margin-right: 14px;
}
.chatbody .bubble {
  display: inline-block;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 9px;
  max-width: 100%;
}
.terms-bottom {
  width: 100vw;
  height: 40px;
  flex-grow: 0;
  background-color: #8c827a;
}
.terms-box {
  margin-top: 5px;
  margin-left: 15px;
  width: 138px;
  height: 30px;
  flex-grow: 0;
  border-radius: 5px;
  border: solid 2px #eae8db;
}
.terms-box p {
  width: 104.6px;
  height: 14px;
  flex-grow: 0;
  margin-top: -23px;
  margin-left: 27px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #eae8db;
}
.read-accepted {
  width: 522px;
  height: 24px;
  margin-top: -31px;
  margin-left: 161px;
  flex-grow: 0;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.44px;
  text-align: left;
  color: #e3e5e5;
}
.chatbody .intentbubble {
  display: inline-block;
  padding: 2px 0px 2px 9px;
  margin-bottom: 5px;
  border-radius: 9px;
  width: 'fit-content' !important;
  max-width: 195px !important;
  min-width: 160px !important;
}
.chatbody .intentbubble p {
  margin-bottom: 0rem !important;
  font-size: 15px;
  text-align: left;
  line-height: 1.4;
  word-break: break-word;
  font-family: 'Roboto';
  flex-grow: 0;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.chatbody .bubble p {
  margin-bottom: 0rem !important;
  font-size: 16px;
  text-align: left;
  line-height: 1.4;
  word-break: break-word;
  font-family: 'Roboto';
  flex-grow: 0;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.chatbody .incoming {
  text-align: left;
  display: 'flex';
}
.chatbody .incoming .bubble {
  color: #333333;
  background-color: rgb(255, 254, 254);
}
.chatbody .incoming .bubble .component {
  background-color: none;
}
.chatbody .outgoing {
  text-align: right;
}
.chatbody .outgoing .bubble {
  margin-right: 10px !important;
  color: #fdfdff;
  background-color: #4f4f4f;
}
.intent {
  background-color: '#54337A' !important;
  color: #fff;
  margin-right: 5px;
  cursor: 'pointer';
}
.spinner {
  width: 70px;
  text-align: left;
}

.spinner > div {
  width: 10px;
  height: 10px;
  background-color: #333;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/* WebChatDesktop */
.dismissable-info .dismiss {
  position: absolute;
  top: 11px;
  right: 11px;
  font-size: 18px;
  color: #f2f2f2;
  cursor: pointer;
}
.dismissable-info {
  font-family: Roboto;
  text-align: left;
  flex-grow: 0;
  padding: 11.8px 41px 17.1px 12px;
  border-radius: 5px;
  background-color: #8c827a;
  font-size: 15px;
  line-height: 1.33;
  font-weight: 400;
  letter-spacing: normal;
  color: #f2f2f2;
  position: relative;
  margin-bottom: 10px;
  margin-top: 14px;
}
.web_Chat_container {
  /* width: 1440px; */
  height: 89vh;
  width: 98vw;
  padding: 5px 118px 258px 40px;
  object-fit: contain;
}
.web_top_usernameChat {
  text-transform: uppercase;
  width: 100% !important;
  height: 50px;
  font-family: 'Anton', sans-serif;
  font-size: 4vw;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  color: #000000;
}
.search-icon {
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 8; /*this will keep the icon appearing all time, even when on input::focus*/
}
.search-icon-caryn {
  position: absolute;
  right: 20px;
  top: 15px;
  z-index: 8;
}
.form-control {
  padding-left: 1.75rem;
}
.bottonChatIcon {
  display: none !important;
  padding-left: 90vw;
}
.web-Chat-Icon {
  display: block;
}
.askCarynHeader {
  font-family: Tungsten;
  font-size: 20px;
  color: #fff;
}
.askCarynSearchTab {
  background-color: #cdaccf;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  position: fixed;
  width: 100vw;
  top: 80;
  z-index: 10;
}
.form-control .inputboxAskCaryn {
  height: 40 !important;
  border-radius: 40 !important;
  padding-right: 40 !important;
  padding-left: 20 !important;
}
.searchfont {
  line-height: 1.4;
  word-break: break-word;
  font-family: 'Roboto';
  flex-grow: 0;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.form-control:focus {
  box-shadow: 0 0 0 0 !important;
}
@media only screen and (max-width: 768px) {
  .bottonChatIcon {
    display: block !important;
    padding-left: 80vw;
  }
  .web-Chat-Icon {
    display: none;
  }
}

.Read-More {
  width: 90px;
  height: 19px;
  flex-grow: 0;
  /* margin: 0 5px 0 0; */
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--white);
}

.Rectangle-267 {
  width: 130px;
  height: 40px;
  flex-grow: 0;
  margin: 7px 161px 0 0;
  /* padding: 10px 12.2px 11px 14px; */
  border-radius: 9px;
  background-color: #162242;
  border: none;
}
