.myneeds {
  border: 1px solid red;
  margin-top: 100px;
  width: 80%;
  /* display: flex;
    align-items: center;
    justify-content: center;
    text-align: center; */
  /* float:center; */
  margin-left: auto;
  margin-right: auto;

  background-color: white;
}
.containers {
  margin-top: 0% !important;
}
.containersMob{
  margin-top: 24% !important;
}
.my-modal {
  width: 90%;
} 
.MuiTableCell-root{
  padding: 2px;
}
.MuiTableCell-root {
  display: table-cell;
  padding: 4px !important;
  font-size: 0.875rem;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.43;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  letter-spacing: 0.01071em;
  vertical-align: inherit;
}
.number-View-download {
  font-size: 12px !important;
  font-weight: 500 !important;
  font-stretch: normal;
  cursor: pointer;
  font-style: normal;
  line-height: 1.85;
  letter-spacing: normal;
  text-align: left;
  color: #4782c4 !important;
}
.wordWrap{
  width: 100%;
  word-wrap: break-word;
  display: inline-block;
}
.MuiPhoneNumber-flagButton{
  display: none !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.reimbursement-tilte{
  flex-grow: 0;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: left;
  color: #373a3c;
}
.reimbursement-tilteMob{
  flex-grow: 0;
  font-family: Lato;
  font-size: 3.2vw;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: left;
  color: #373a3c;
}

.reimbursement-ButtonBGcannel {
    width: 93px;
    height: 40px;
    margin: 0 20px 0 0;
    padding: 0 0.2px 0 0;
    border-radius: 30px;
    border: solid 1px #ccc;
    background-color: #fff; 
    color: #000000;
    font-family: Lato;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 1.44px;
    text-align: center;
    color: #000000;
}
.reimbursement-ButtonBGBrowse {
  width: 93px;
  height: 40px;
  margin: 0 20px 0 0;
  padding: 0 0.2px 0 0;
  border-radius: 30px;
  border: solid 1px #ccc;
  background-color: #fff; 
  color: #000000;
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 1.44px;
  text-align: center;
  color: #000000;
}
.reimbursement-ButtonBGsave {
  width: 93px;
  height: 40px;
  margin: 0 20px 0 0;
  padding: 0 0.2px 0 0;
  border-radius: 30px;
  border: solid 1px;
  background-color: #4782c4; 
  color: #000000;
  font-family: Lato;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 1.44px;
  text-align: center;
  color: #ffffff;
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #E3E3E3 !important;
  border-color: #E3E3E3 !important;
}
.reimbursement-ButtonBGsaveDisabled {
  width: 93px;
  height: 40px;
  margin: 0 20px 0 0;
  padding: 0 0.2px 0 0;
  border-radius: 30px;
  border: solid 1px;
  background-color: #E3E3E3; 
  color: #000000;
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 1.44px;
  text-align: center;
  color: #ffffff;
}
.progInfoContainer {
  padding: 0 !important;
  margin-top: 80px;
  position: relative;
}
.commonWrap {
  padding-top: 55px;
}
.commonWrap_MyNeeds {
  /* margin: 0 0 40px 0; */
}
.commonWrap h2 {
  margin: 0;
}
.progInfoSection {
  padding: 20px;
  background: #ffffff;
}
.progInfoTitle {
  font-family: Lato;
  font-size: 42px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #162242;
  text-transform: uppercase;
}
.progInfoTitleHipaa {
  font-family: Lato;
  font-size: 42px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #162242;
  text-transform: uppercase;
}
.progInfoFooter {
  background: #f7f7f7;
  border-top: 1px solid #bdbdbd;
  padding: 5px;
}
.elevation {
  width: 70px;
  height: 70px;
  border-radius: 35px;
  box-shadow: 0 2.5px 5px 0 rgba(0, 0, 0, 0.2), 0 1.3px 12.5px 0 rgba(0, 0, 0, 0.12), 0 5px 6.3px 0 rgba(0, 0, 0, 0.14);
  background-color: #41b5c2;
}
.MuiTableCell-root{
  padding:2px !important;
}
.number-View-download {
  font-family: Lato;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.85;
  letter-spacing: normal;
  text-align: left;
  color: #4782c4;
}
.footerTextLeft {
  /* font-weight: 500; */
  /* margin: 0;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-weight: bold;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    
    color: #333333;
    padding: 2px 0; */
  position: absolute;
  margin: 0;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: right;
  color: #333333;
  /* padding: 2px 0; */
  padding-top: 17px;
}
.dpFlex {
  display: flex;
  flex-direction: row-reverse;
}
.footerText {
  padding: 9px 15px 0 0;
}
.footerText p {
  margin: 0;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: right;
  color: #333333;
  padding: 2px 0;
}
.footerText p:first-child {
  font-weight: bold;
}
.tablebackground {
  background-color: white;
  /* border: 1px solid red; */
  height: 350px;
  padding: 10px 30px;
  overflow: auto;
}

.headercellColor {
  /*color: #8c827a !important;*/
}
.sortheadercellColor {
  /* color: #8c827a !important; */
  background-color: #f9f9fa;
}
.rowcellcolor {
  font-size: 16px;
  /*color: #000000 !important;*/
  font-family: Roboto;
  font-weight: 400;
}
.sortrowcellcolor {
  font-size: 16px;
  /*color: #000000 !important;*/
  font-family: Roboto;
  font-weight: 400;
  background-color: #fff !important;
}

.inreview_button {
  background-color: #eb5757;
  color: #ffffff;
  /* width: 65px; */
  height: 16px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.4px;
  text-align: left;
  padding: 5px 10px;
  border-radius: 4px;
}

.final_button {
  background-color: #27ae60;
  color: #ffffff;
  /* width: 65px; */
  height: 16px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.4px;
  text-align: left;
  padding: 5px 10px;
  border-radius: 4px;
}

.inner_viewbutn {
  color: red;
}

.Audit {
  background-color: #27ae60;
}
.Authorized {
  background-color: #a9a9a9;
}
.Denied {
  background-color: #eb5757;
}

.Reversed {
  background-color: #ffa500;
}
.Pending {
  background-color: #ffa500;
}
.ISSUE {
  background-color: #ffa500;
}
.Logged {
  background-color: #eb5757;
}

.rButton{
  border-radius: 28px;
  width: 200px;
  padding: 5px;
  font-size: 1.1vw;
}